@media (max-width: 992px) {
  .thank_button_center{
    margin: 0 auto;
  }
  .thank_button {
    font-size: 30px;
  }
}



@media (max-width: 800px) {
  .logo {
    width: 27%;
  }
}

@media (max-width: 600px) {
  .logo {
    width: 37%;
  }

  .Modal-View {
    width: 100%;
  }

  .react-tel-input .form-control {
    border-radius: 0px !important;
    padding: 17px 50px !important;
  }

  .modelbutton {
    padding: 10px 30px;
  }

  .titlemodel {
    font-size: 15px;
  }

  .paramodel {
    font-size: 14px;
  }

  .parahighlighttext {
    font-size: 19px !important;
  }

  .inputbox {
    padding: 5px 5px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !;
  }

  .watchtext {
    font-size: 31px;
  }

  .applybutton {
    padding: 15px 30px;
    font-size: 23px;
  }

  .freecall {
    font-size: 25px;
  }

  .webinartext {
    font-size: 20px;
  }

  .book {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .logo {
    width: 50%;
  }

  .title {
    font-size: 34px;
    line-height: 48px;
  }

  .subtitle {
    font-size: 27px;
  }

  .subsubtitle {
    font-size: 15px;
  }

  .para {
    font-size: 23px;
    text-align: center;
    margin-top: 20px;
  }

  .subpara {
    font-size: 16px;
  }

  .icon {
    font-size: 18px;
  }

  .button {
    font-size: 22px;
  }

  .calenderlogo {
    width: 50%;
  }

  .thankstitle {
    font-size: 31px;
  }

  .thankstitle1 {
    font-size: 25px;
  }

  .startext {
    font-size: 16px;
    font-weight: bold;
  }

  .starimage {
    width: 85%;
  }

  .videoalignment {
    height: 220px !important;
  }

  .form-item input {
    width: 100%;
  }
  .thank_button {
    font-size: 14px;
  }
  .thank_button_center{
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 33px;
    line-height: 48px;
  }

  .applybutton {
    padding: 15px 25px;
    font-size: 23px;
  }

  .watchtext {
    font-size: 30px;
  }

  .button {
    font-size: 25px;
    padding: 15px 40px;
  }

  .modelbutton {
    padding: 10px 25px;
  }

  .para {
    font-size: 27px;
  }

  .logo {
    width: 55%;
  }

  .form-item input {
    width: 100%;
  }

  .thank_button {
    font-size: 14px;
  }
  .thank_button {
    margin-bottom: 1rem;
  }
  .thank_button_center{
    margin: 0 auto;
  }
}